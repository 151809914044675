.text_color_1 {
    font-weight: 600;
}

.listing_changes_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

@media (max-width: 768px) {
    .listing_changes_container{
        flex-direction: column;
    }
}

.listing_changes_container_1{
    width: 35%;
}

.listing_changes_container_2{
    width: 64%;
}

.listing_changes_sub_container {
    margin: 3px;
    padding: 3px;
    border: 1px solid #89898a;
    border-radius: 4px;
}

.listing_changes_sub_container_active {
    border: 2px solid #ea4335;
}

.listing_changes_key_value_container {
    display: flex;
    flex-direction: row;
    gap: 3px;
    flex-wrap: wrap;
}

.listing_changes_key {
    font-weight: bold;
}

.listing_changes_value {
    font-weight: 500;
}

.listing_changes_objects_container{
    display: flex;
    flex-direction: row;
    gap: 5px;
    flex-wrap: wrap;
}