/* styles.css */
input::placeholder {
    /* color: #000000; */
    font-size: 0.875rem;
    opacity: 0.75;
}
.sp_input_tel {
    min-width: 74px;
    width: 25%;
    height: 49px;
    padding: 5px 10px 5px 10px;
    /* padding-right: 60px; */
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 0.875rem;
    margin-right: 5px;
}
.sp_input {
    width: 100%;
    /* height: 35px; */
    padding: 10px 15px 10px 15px;
    /* padding-right: 60px; */
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 0.875rem;
}
.sp_input.error {
    border-color: #ea4335;
    outline: none;
}
.sp_input:focus {
    background-color: #f3f4f6;
    outline: none;
}
.sp_input_tel:focus {
    background-color: #f3f4f6;
    outline: none;
}

.sp_textarea {
    width: 100%;
    height: 100px;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 4px;
}
.sp_textarea.error {
    border-color: #ea4335;
    outline: none;
}
.sp_textarea:focus {
    background-color: #f3f4f6;
    outline: none;
}
.sp_select_container {
    position: relative;
    width: 100%;
    z-index: 9;
}
.sp_multi_select_container {
    position: relative;
    width: 100%;
    z-index: 1;
}
.sp_options_list {
    position: absolute;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #ffffff;
    max-height: 250px;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    list-style-type: none; /* Remove default list styling */
    z-index: 9;
}

.sp_option_item {
    position: relative;
    z-index: 9;
    padding: 8px;
    cursor: pointer;
    background-color:#ffffff;
    font-size: 0.875rem;
}

.sp_option_item:hover {
    background-color: #f0f0f0;
    color: #333;
}
.sp_option_item.highlighted {
    background-color: #f0f0f0; /* Highlight color */
}

.sp-selected-option {
    margin-top: 8px;
    font-weight: bold;
}
.sp_text_container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.sp_check{
    height: 20px;
    margin-right: 5px;
    margin-top: .05em;
    width: 20px;
    accent-color: #ea4335;
    border-radius: 0.25em;
    cursor: pointer;
}
.sp_check_box_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
}
/* .sp_multi_select_container{
    
}
.sp_multi_selected_options{
    display: flex;
    flex-direction: row;
    align-items: center;
} */
/* Hide arrows in Firefox */
input[type=number] {
    -moz-appearance: textfield;
  }
/* Hide arrows in Chrome, Safari, and Edge */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="password"]::-ms-reveal {
    display: none;
  }
  
  input[type="password"]::-ms-clear {
    display: none;
  }
.sp_input_container {
    position: relative;
    width: 100%;
}
  
.sp_input_icon {
    position: absolute;
    right: 0;
    top: 50%;
    margin-right: 10px;
    transform: translateY(-50%);
}

/* for multi select component start */
.sp_multi_chip {
    background-color: #e0e0e0;
    border-radius: 16px;
    padding: 5px 10px;
    margin: 5px;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
}
  
.sp_multi_chip_remove {
    background: none;
    border: none;
    font-size: 16px;
    margin-left: 8px;
    cursor: pointer;
}
.sp_select_input_container{
    position: relative;
}
.sp_select_icon_container{
    position: absolute;
    top: 11px;
    right: 3px;
    width:auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
}
.sp_multi_select_icon_container{
    position: absolute;
    /* top: 11px; */
    margin-left: -55px;
    bottom: 10px;
    right: 3px;
    width:auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
}
.sp_select_icon_clear{

}
.sp_select_icon_arrow{
    margin-left: 5px;
}
.react-datepicker-wrapper{
    margin-top: -43px !important;
    opacity: 0;
    z-index: -1;
}
.react-datepicker-popper{
    z-index: 3 !important;
}

/* .sp_options_list {
    list-style: none;
    padding: 0;
    margin: 10px 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 200px;
    overflow-y: auto;
} */

/* .sp_option_item {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.sp_option_item:hover {
    background-color: #f0f0f0;
} */

/* .sp_input {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
} */

/* .sp_input.error {
    border-color: red;
} */

/* .error_text {
    color: red;
    font-size: 12px;
} */

/* for multi select component end */


@media (max-width : 768px) {
    .sp_input_tel{
        min-width: 71px;
        width: 35%;
        padding: 5px 5px 5px 5px;
    }
}