.invoiceMainContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.invoicePageContainer{
    width: 210mm;
    min-height: 200mm;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 4px 44px 0 rgba(0, 0, 0, .12);
    padding: 10px;
    margin-top: 15px;
}
.invoiceButtonContainer{
    width: 210mm;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-top: 15px;
    gap: 10px;
}
/* .invoiceHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}
.invoiceHeader img{
    width: 171px;
    height: 51px;
}
.invoiceBody{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.invoiceKeyText{
    color: #000000;
    font-weight: 500;
}
.invoiceValueText{
    color: #919191;
} */



/* new invoice start */
/* Invoice.css */
.invoice {
    /* font-family: Arial, sans-serif; */
    padding: 20px;
    border: 1px solid #000;
    max-width: 800px;
    margin: auto;
    color: #333;
  }
  
  .invoiceheader {
    text-align: start;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #000;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  .invoicecompany-details{
    max-width: 70%;
  }
  
  .invoicecompany-details h1 {
    color: rgb(95, 95, 95);
  }
  
  .invoice-details h2 {
    color: grey;
  }
  .invoicepara{
    color: grey;
  }
  .invoiceheading-color{
    background-color: #ff0000;
    padding: 5px;
    color: #fff;
  }
  
  .invoiceaddresses {
    margin-bottom: 20px;
    width:100%;
  }
  
  .invoicebilled-to, .invoiceshipped-to {
    margin-bottom: 20px;
    text-align: start;
  }
  
  .invoiceinvoice-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .invoice-table th, .invoice-table td {
    border: 1px solid #000;
    padding: 10px;
    text-align: left;
  }
  
  .invoice-table th {
    background-color: #ff0000;
    color: #fff;
  }
  .invoiceacc-details{
    display: flex;
    align-items: space-between;
  }
  .invoicesummary {
    text-align: right;
    /* margin-right: 10px; */
  }
  
  .invoicesummary p {
    margin: 5px 0;
  }
  
  
  .invoicefooter {
    border-top: 2px solid #000;
    padding-top: 10px;
    text-align: center;
  }
  
  .invoicefooter {
    margin: 5px 0;
    border: 1px solid black;
  }
  
  .invoicecompany-details p, .invoice-details p, .invoicebilled-to p, .invoiceshipped-to p{
    margin-top: 10px;
    margin: 5px 0;
    max-width: 60%;
  }

  .invoice_main_text {
    font-family: 'Rubik' !important;
    font-size: 1.5rem;
  }
  .invoice_content {
    font-size: 0.875rem;
  }

  @media (max-width : 768px) {
    .invoice_main_text{
      font-family: 'Rubik' !important;
      font-size: 1.25rem;
    }
  }
/* invoice table styling start  */

.invoiceinvoice-table th,
tfoot td {
  padding-top: 25px;
  padding-bottom: 25px;
  text-align: center;
  background-color: #000;
  color: white;
}

.invoiceinvoice-table td,
.invoiceinvoice-table th {
  border: 1px solid #ddd;
  padding: 8px;
}

@media (max-width: 991px) {

  .invoiceinvoice-table td,
  .invoiceinvoice-table th {
    border: 1px solid #ddd;
    padding: 3px;
  }
}

/* invoice table styling end  */
/* new invoice end */